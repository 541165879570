.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.date-picker-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    border-color 0.2s,
    box-shadow 0.2s;
}

.date-picker-input:focus {
  border-color: #0056b3;
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.35);
}

.react-datepicker {
  font-family: "Roboto", sans-serif;
  border: 2px solid #e6e6e6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
}

.react-datepicker__header {
  background-color: #0056b3;
  color: #ffffff;
  border-bottom: 1px solid #e6e6e6;
  padding: 8px 0;
  text-align: center;
}

.react-datepicker__current-month {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day {
  width: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #0056b3;
  color: white;
}

.react-datepicker__day--today {
  font-weight: bold;
  background-color: #ff6f61;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #a7e2f9;
  color: #0056b3;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__month-container {
  background-color: #f7f7f7;
}

.react-datepicker__day-name {
  color: #ffffff;
  font-weight: bold;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 15px;
  line-height: 1.7rem;
}

.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

.react-datepicker__navigation--next {
  border-left-color: #ffffff;
}
