/* Custom DatePicker Styles for Consistency */
.date-picker-input {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.date-picker-input:focus {
  outline: none;
  border-color: #007174;
  box-shadow: 0px 0px 5px rgba(0, 113, 116, 0.5);
}
